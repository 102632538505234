import { Notify } from "notiflix";
import axios from "axios";
import queryString from "query-string";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  paramsSerializer(params) {
    return queryString.stringify(params, { indices: false });
  },
});

// Request Interceptors
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response Interceptors
axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data.success) {
      if (response.config.method !== "get") {
        Notify.success(response.data.message || response.data.info, {
          ID: "MKA",
          timeout: 8000,
          useIcon: false,
          fontSize: "14px",
          position: "right-top",
          className: "h-[50px]",
          width: "350px",
          messageMaxLength: 500,
          showOnlyTheLastOne: true,
          success: {
            textColor: "#026e0d",
            background: "#e9fbec",
          },
        });
      }
      return response;
    } else if (!response.data.success) {
      Notify.failure(response.data.message || response.data.info, {
        ID: "MKA",
        timeout: 8000,
        useIcon: false,
        fontSize: "14px",
        position: "right-top",
        className: "h-[50px]",
        width: "350px",
        messageMaxLength: 500,
        showOnlyTheLastOne: true,
        backOverlayColor: "rgba(0,0,0,0.5)",
        failure: {
          textColor: "#e40909",
          background: "#ffece5",
        },
      });
      // if (response.data.info === "Session has expired. Please login again. Error Code L105") {
      //   localStorage.clear();
      //   window.location = "/onboarding/sign-in";
      // }

      return response;
    } else {
      const messages = response.data.messages;
      if (messages) {
        if (messages instanceof Array) {
          return Promise.reject({ messages });
        }
        return Promise.reject({ messages: [messages] });
      }
      return Promise.reject({ messages: ["got errors"] });
    }
  },
  (error) => {
    //unauthorised error
    Notify.failure(error.response.data.message, {
      ID: "MKA",
      timeout: 8000,
      useIcon: false,
      fontSize: "14px",
      position: "right-top",
      className: "h-[50px]",
      width: "350px",
      messageMaxLength: 500,
      showOnlyTheLastOne: true,
      backOverlayColor: "rgba(0,0,0,0.5)",
      failure: {
        textColor: "#e40909",
        background: "#ffece5",
      },
    });
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      window.location = "/onboarding/sign-in";
    }
    //internal server error
    else if (error.response && error.response.status === 500) {
      return Promise.reject(error.response);
    }
    //any other error
    else return Promise.reject(error);
  }
);

export default axiosInstance;
