import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { postReq } from "../../Hooks/Mutation/Mutation";
import { LoadingOutlined } from "@ant-design/icons";
import LogoutIcon from "../../Assets/images/logout.svg";

export const Logout = ({ showDialog, setShowDialog }) => {
  const navigate = useNavigate();

  // Queries & Mutation
  const mutation = useMutation({
    mutationFn: postReq,
  });

  const handleSubmit = () => {
    mutation
      .mutateAsync({
        route: `vendors/logout/`,
        payload: {},
      })
      .then((res) => {
        navigate("/onboarding/sign-in");
        setTimeout(() => {
          localStorage.clear();
        }, 2000);
      })
      .catch((error) => {});
  };

  return (
    <div className="w-[100%]">
      <div>
        <img src={LogoutIcon} className="mx-auto" alt="" />
        <p className="text-[24px] text-[#0F172A] font-cereal_bold text-center my-[1rem]">Logout</p>
        <p className="text-[#222222] font-cereal text-[12px] text-center">
          Are you sure you want to logout from your ZinID account?
        </p>
        <button
          type="submit"
          onClick={handleSubmit}
          className="h-[54px] w-[100%] bg-danger text-secondary rounded-[4px] font-cereal_medium text-[14px] my-[1.5rem]"
        >
          {mutation.isPending ? <LoadingOutlined /> : "Yes, Log out"}
        </button>
        <p
          className="text-[#222222] font-cereal text-[12px] text-center mb-5 cursor-pointer"
          onClick={() => setShowDialog(!showDialog)}
        >
          Cancel
        </p>
      </div>
    </div>
  );
};
