/* eslint-disable react-hooks/exhaustive-deps */
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { IoIosStats } from "react-icons/io";
import { GoPeople } from "react-icons/go";
import { IoCardOutline, IoCloseOutline, IoDiceOutline, IoPersonOutline } from "react-icons/io5";
import { IoIosNotificationsOutline } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import { IoPeopleCircleSharp } from "react-icons/io5";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { CiLogout } from "react-icons/ci";
import { FaCode } from "react-icons/fa6";
import { Avatar, Divider, Drawer, Dropdown, Image, Switch } from "antd";
import { BiSearchAlt } from "react-icons/bi";
import { LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { Logout } from "./Logout/LogOut";
import { FaAngleDown } from "react-icons/fa";
import { getReq } from "../Hooks/Query/Query";
import { useQuery } from "@tanstack/react-query";
import Logo from "../Assets/images/logo.png";
import NoNotification from "../Assets/images/no_notification.svg";
import ModalComponent from "../Compoments/Modal";
import RouteGuard from "../Util/RouteGuard";
import moment from "moment";
import queryString from "query-string";

const sidebar = [
  {
    title: "Overview",
    icon: <IoIosStats size="24px" />,
    active: <IoIosStats color="#0166FF" size="24px" />,
    route: "/dashboard/overview",
  },
  {
    title: "Users",
    active: <GoPeople color="#0166FF" size="24px" />,
    icon: <GoPeople size="24px" />,
    route: "/dashboard/users",
  },
  {
    title: "Members",
    active: <IoPeopleCircleSharp color="#0166FF" size="24px" />,
    icon: <IoPeopleCircleSharp size="24px" />,
    route: "/dashboard/members",
  },
  {
    active: <IoDiceOutline color="#0166FF" size="24px" />,
    icon: <IoDiceOutline size="24px" />,
    title: "Apps & integrations",
    route: "/dashboard/app-integration?tab=all",
  },
  {
    active: <LiaFileInvoiceDollarSolid color="#0166FF" size="24px" />,
    icon: <LiaFileInvoiceDollarSolid size="24px" />,
    title: "Billing",
    route: "/dashboard/billing",
  },
  {
    active: <FaCode color="#0166FF" size="24px" />,
    icon: <FaCode size="24px" />,
    title: "Api Documentation",
    route: "/documentation?screen=welcome",
  },
];

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = useLocation();
  const { type } = queryString.parse(search);
  const [showDialog, setShowDialog] = useState(false);
  const [isProduction, setIsProduction] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showMobileNotification, setShowMobileNotification] = useState(false);

  const { data } = useQuery({
    queryKey: ["get-business-information", { route: `vendors/business_info/`, params: {} }],
    queryFn: getReq,
  });

  const { data: userProfile } = useQuery({
    queryKey: ["get-user-information", { route: `vendors/members/self/`, params: {} }],
    queryFn: getReq,
  });

  useEffect(() => {
    localStorage.setItem("user_information", JSON.stringify(userProfile?.data));
  }, [userProfile?.data]);

  const sidebar2 = [
    {
      title: "Settings",
      active: <IoSettingsOutline color="#0166FF" size="24px" />,
      icon: <IoSettingsOutline size="24px" />,
      route: "/dashboard/settings?type=profile",
    },
  ];

  const items = [
    {
      key: "1",
      label: (
        <NavLink
          to="/dashboard/settings?type=general"
          className={({ isActive }) =>
            isActive
              ? "bg-[#EEF5FF] p-2 font-cereal_medium text-[14px] text-[#0166FF] flex item-center gap-2 rounded-[4px]"
              : "p-2 text-[#667085] flex items-center gap-2 font-cereal_medium text-[14px]"
          }
        >
          <IoPersonOutline size="18px" />
          Profile
        </NavLink>
      ),
    },
    {
      key: "4",
      label: (
        <NavLink
          to="/dashboard/settings?type=profile"
          className={({ isActive }) =>
            isActive
              ? "bg-[#EEF5FF] p-2 font-cereal_medium text-[14px] text-[#0166FF] flex item-center gap-2 rounded-[4px]"
              : "p-2 text-[#667085] flex items-center gap-2 font-cereal_medium text-[14px]"
          }
        >
          <IoSettingsOutline size="18px" />
          Settings
        </NavLink>
      ),
    },
    {
      key: "5",
      label: (
        <div
          className="p-2 flex items-center gap-2 font-cereal_medium text-[14px] text-[red] cursor-pointer"
          onClick={() => {
            setShowDialog(!showDialog);
          }}
        >
          <CiLogout size="18px" />
          Logout
        </div>
      ),
    },
    {
      key: "6",
      label: <div className="px-2 font-cereal_medium text-[14px] cursor-pointer">Help Center</div>,
    },
    {
      key: "7",
      label: <div className="px-2 font-cereal_medium text-[14px] cursor-pointer">Book a free demo</div>,
    },
    {
      key: "1",
      label: <div className="px-2 font-cereal_medium text-[14px] cursor-pointer">System status</div>,
    },
  ];

  return (
    <RouteGuard>
      <div className="flex">
        <div
          className="hidden w-[250px] border-r border-r-[#EAECF0] h-[100vh] lg:flex justify-between p-5"
          style={{ flexDirection: "column" }}
        >
          <div>
            <NavLink to="/">
              <img src={Logo} className="w-[100px]" alt="" />
            </NavLink>
            <div className="mt-[3rem]">
              {sidebar.map((item, index) => {
                if (item.title === "Api Documentation") {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        navigate("/dashboard/overview");
                        window.open(item.route, "_blank");
                      }}
                      className={
                        "p-2 text-[#667085] flex items-center gap-4 font-cereal_medium text-[14px] my-3 cursor-pointer"
                      }
                    >
                      {location.pathname === item.route ? item.active : item.icon}
                      {item.title}
                    </div>
                  );
                } else {
                  return (
                    <NavLink
                      end
                      key={index}
                      to={item.route}
                      className={({ isActive }) =>
                        isActive
                          ? "bg-[#EEF5FF] p-2 font-cereal_medium text-[14px] text-[#0166FF] flex item-center gap-4 rounded-[4px]"
                          : "p-2 text-[#667085] flex items-center gap-4 font-cereal_medium text-[14px] my-3"
                      }
                    >
                      {location.pathname === item.route ? item.active : item.icon}
                      {item.title}
                    </NavLink>
                  );
                }
              })}
            </div>
          </div>
          <div className="mt-[3rem]">
            {sidebar2.map((item, index) => (
              <NavLink
                key={index}
                to={item.route}
                className={({ isActive }) =>
                  isActive
                    ? "bg-[#EEF5FF] p-2 font-cereal_medium text-[14px] text-[#0166FF] flex item-center gap-4 rounded-[4px]"
                    : "p-2 text-[#667085] flex items-center gap-4 font-cereal_medium text-[14px] my-3"
                }
              >
                {location.pathname === item.route ? item.active : item.icon}
                {item.title}
              </NavLink>
            ))}
            <div
              className="p-2 text-[#667085] flex items-center gap-4 font-cereal_medium text-[14px] my-3 cursor-pointer"
              onClick={() => {
                setShowDialog(!showDialog);
              }}
            >
              <CiLogout size="24px" />
              Logout
            </div>
            <div className="flex items-center gap-3 mt-4 border-t border-t-[#EAECF0] pt-4">
              <Avatar size={35}>
                {userProfile?.data?.user__first_name[0]}
                {userProfile?.data?.user__last_name[0]}
              </Avatar>
              <div>
                <h4 className="font-cereal_bold text-[12px] text-[#344054]">
                  {userProfile?.data?.user__first_name} {userProfile?.data?.user__last_name}
                </h4>
                <h6 className="font-inter text-[#475467] text-[12px]">{userProfile?.data?.user__email}</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#FBFBFB] w-[100vw] block lg:w-[calc(100vw-250px)]">
          <div className="flex items-center justify-between bg-secondary px-6 py-3">
            <img src={data?.data?.Logo} className="lg:hidden h-[30px]" alt="" />
            {location.pathname === "/dashboard/app-integration" ? (
              <div className="hidden lg:block">
                <h3 className="text-[23px] font-cereal_medium text-[#272E35]">Integrations</h3>
                <p className="text-[14px] text-[#272E35]">Signup on Other  Platforms as a Business</p>
              </div>
            ) : type === "overview" ? (
              <div className="hidden lg:block">
                <div className="flex gap-5 items-center">
                  <h3 className="text-[23px] font-cereal_medium text-[#272E35]">Overview</h3>
                  <div className="flex items-center gap-2">
                    <h2 className={`${!isProduction && "font-cereal_medium"} text-[16px] text-[#7E8B99]`}>Test</h2>
                    <Switch
                      size="small"
                      checked={isProduction}
                      defaultChecked={isProduction}
                      onChange={() => setIsProduction(!isProduction)}
                    />
                    <h2 className={`${isProduction && "font-cereal_medium"} text-[16px] text-[#7E8B99]`}>Production</h2>
                  </div>
                </div>
              </div>
            ) : type === "webhook" ? (
              <div className="hidden lg:block">
                <div className="flex gap-5 items-center">
                  <h3 className="text-[23px] font-cereal_medium text-[#272E35]">Webhook Manager</h3>
                  <div className="flex items-center gap-2">
                    <h2 className={`${!isProduction && "font-cereal_medium"} text-[16px] text-[#7E8B99]`}>Test</h2>
                    <Switch
                      size="small"
                      checked={isProduction}
                      defaultChecked={isProduction}
                      onChange={() => setIsProduction(!isProduction)}
                    />
                    <h2 className={`${isProduction && "font-cereal_medium"} text-[16px] text-[#7E8B99]`}>Production</h2>
                  </div>
                </div>
              </div>
            ) : type === "api-keys" ? (
              <div className="hidden lg:block">
                <div className="flex gap-5 items-center">
                  <h3 className="text-[23px] font-cereal_medium text-[#272E35]">API Keys</h3>
                  <div className="flex items-center gap-2">
                    <h2 className={`${!isProduction && "font-cereal_medium"} text-[16px] text-[#7E8B99]`}>Test</h2>
                    <Switch
                      size="small"
                      checked={isProduction}
                      defaultChecked={isProduction}
                      onChange={() => setIsProduction(!isProduction)}
                    />
                    <h2 className={`${isProduction && "font-cereal_medium"} text-[16px] text-[#7E8B99]`}>Production</h2>
                  </div>
                </div>
              </div>
            ) : type === "sdk" ? (
              <div className="hidden lg:block">
                <div className="flex gap-5 items-center">
                  <h3 className="text-[23px] font-cereal_medium text-[#272E35]">Web SDK</h3>
                  <div className="flex items-center gap-2">
                    <h2 className={`${!isProduction && "font-cereal_medium"} text-[16px] text-[#7E8B99]`}>Test</h2>
                    <Switch
                      size="small"
                      checked={isProduction}
                      defaultChecked={isProduction}
                      onChange={() => setIsProduction(!isProduction)}
                    />
                    <h2 className={`${isProduction && "font-cereal_medium"} text-[16px] text-[#7E8B99]`}>Production</h2>
                  </div>
                </div>
              </div>
            ) : location.pathname === "/dashboard/overview" || location.pathname === "/dashboard/users" ? (
              <div className="flex items-center gap-[1rem]">
                <div className="hidden md:flex items-center h-[45px] bg-[#6565650D] xl:w-[350px] ps-[1rem]">
                  <BiSearchAlt size={25} color="#656565" />
                  <input
                    type="search"
                    className="w-[100%] font-cereal text-[12px] bg-[transparent] text-[#656565] h-[100%] ps-3 outline-none"
                    placeholder="Search for a user"
                    name=""
                    id=""
                  />
                </div>
                <div className="hidden bg-[#F4F7FA] rounded-[4px] px-[1rem] h-[45px] md:flex items-center gap-3 cursor-pointer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                    <g clip-path="url(#clip0_1494_28079)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1.26172 5.625C1.26172 5.27982 1.54154 5 1.88672 5H19.3867C19.7319 5 20.0117 5.27982 20.0117 5.625C20.0117 5.97018 19.7319 6.25 19.3867 6.25H1.88672C1.54154 6.25 1.26172 5.97018 1.26172 5.625ZM4.38672 10C4.38672 9.65482 4.66654 9.375 5.01172 9.375H16.2617C16.6069 9.375 16.8867 9.65482 16.8867 10C16.8867 10.3452 16.6069 10.625 16.2617 10.625H5.01172C4.66654 10.625 4.38672 10.3452 4.38672 10ZM8.13672 14.375C8.13672 14.0298 8.41654 13.75 8.76172 13.75H12.5117C12.8569 13.75 13.1367 14.0298 13.1367 14.375C13.1367 14.7202 12.8569 15 12.5117 15H8.76172C8.41654 15 8.13672 14.7202 8.13672 14.375Z"
                        fill="#373737"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1494_28079">
                        <rect width="20" height="20" fill="white" transform="translate(0.636719)" />
                      </clipPath>
                    </defs>
                  </svg>
                  <p className="font-cereal text-[12px]">Filter</p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                    <path
                      d="M14.8867 6.375L9.63672 11.625L4.38672 6.375"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linecap="square"
                    />
                  </svg>
                </div>
              </div>
            ) : (
              <div></div>
            )}
            <div className="hidden lg:block">
              <div className="flex items-center cursor-pointer gap-4">
                <IoIosNotificationsOutline
                  size={26}
                  className="hidden md:block"
                  onClick={() => setShowNotification(!showNotification)}
                />
                <Image src={data?.data?.logo} height={45} width={45} className="rounded-[50%]" alt="" />
                <Dropdown menu={{ items }} arrow trigger="click" placement="bottom">
                  <div className="hidden lg:flex items-center gap-3 border-[1px] border-[#EAECF0] bg-secondary p-2 rounded-[4px]">
                    <h4 className="font-cereal_medium text-[17px] whitespace-nowrap text-[#101828]">
                      {data?.data?.name}
                    </h4>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                      <path
                        d="M4.63672 6L8.63672 10L12.6367 6"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </Dropdown>
                <Avatar
                  size="large"
                  className="lg:hidden"
                  style={{
                    backgroundColor: "#f56a00",
                  }}
                >
                  FA
                </Avatar>
                <FaAngleDown className="lg:hidden" />
              </div>
            </div>
            <div className="lg:hidden flex items-center gap-4">
              {/* <IoIosNotificationsOutline size={26} onClick={() => setShowMobileNotification(!showMobileNotification)} /> */}
              <Dropdown menu={{ items }} trigger="click" placement="bottom" arrow>
                <div className="flex items-center cursor-pointer gap-4">
                  <img src={data?.data?.logo} className="rounded-[50%] h-[30px]" alt="" />
                  <div className="hidden lg:flex items-center gap-3 border-[1px] border-[#EAECF0] bg-secondary p-2 rounded-[4px]">
                    <h4 className="font-cereal_medium text-[17px] whitespace-nowrap text-[#101828]">
                      {data?.data?.name}
                    </h4>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                      <path
                        d="M4.63672 6L8.63672 10L12.6367 6"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <FaAngleDown className="lg:hidden" />
                </div>
              </Dropdown>
            </div>
          </div>
          <div className="onboarding-content overflow-y-scroll">
            <Outlet />
          </div>

          {/* Mobile Navigation */}
          <div className="flex lg:hidden items-center justify-between gap-4 sticky bottom-0 bg-secondary shadow px-2">
            {sidebar.map((item, index) => {
              if (item.title === "Api Documentation") {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      navigate("/dashboard/overview");
                      window.open(item.route, "_blank");
                    }}
                    className={"p-1 text-[#667085] flex flex-col justify-center text-[10px] my-2"}
                  >
                    <div className="flex items-center justify-center">
                      {location.pathname === item.route ? item.active : item.icon}
                    </div>
                    <span className="font-cereal_bold">
                      {item.title === "Apps & integrations"
                        ? "Apps"
                        : item.title === "Api Documentation"
                        ? "Api doc"
                        : item.title}
                    </span>
                  </div>
                );
              } else {
                return (
                  <NavLink
                    key={index}
                    to={item.route}
                    className={({ isActive }) =>
                      isActive
                        ? "p-1 text-[10px] text-[#0166FF] text-center rounded-[4px] my-2"
                        : "p-1 text-[#667085] flex flex-col justify-center text-[10px] my-2"
                    }
                  >
                    <div className="flex items-center justify-center">
                      {location.pathname === item.route ? item.active : item.icon}
                    </div>
                    <span className="font-cereal_bold">
                      {item.title === "Apps & integrations"
                        ? "Apps"
                        : item.title === "Api Documentation"
                        ? "Api doc"
                        : item.title}
                    </span>
                  </NavLink>
                );
              }
            })}
          </div>
        </div>
        {showNotification && (
          <div
            className="absolute top-0 bottom-0 left-0 right-0 bg-[#0F172A73] p-5 hidden lg:block"
            // onClick={() => setShowNotification(!showNotification)}
          >
            <Notification {...{ showNotification, setShowNotification }} />
          </div>
        )}

        <Drawer
          width="80%"
          key="right"
          closable={false}
          placement="right"
          open={showMobileNotification}
          onClose={() => setShowMobileNotification(!showMobileNotification)}
        >
          <Notification {...{ showNotification, setShowNotification }} />
        </Drawer>

        {/* Log-Out Modal */}
        <ModalComponent
          closeIcon={false}
          showDialog={showDialog}
          setShowDialog={setShowDialog}
          component={<Logout {...{ showDialog, setShowDialog }} />}
        />
      </div>
    </RouteGuard>
  );
};

export default Dashboard;

const Notification = ({ showNotification, setShowNotification }) => {
  const [active, setActive] = useState("All notification");
  const [notifications, setNotifications] = useState([]);
  const categories = ["All notification", "Unread", "Read"];

  const { data: all, isLoading } = useQuery({
    queryKey: ["get-all-notifications", { route: `vendors/notifications/`, params: {} }],
    queryFn: getReq,
  });

  const { data: unread } = useQuery({
    queryKey: ["get-unread-notifications", { route: `vendors/notifications/unread/`, params: {} }],
    queryFn: getReq,
  });

  const { data: read } = useQuery({
    queryKey: ["get-read-notifications", { route: `vendors/notifications/read/`, params: {} }],
    queryFn: getReq,
  });

  useEffect(() => {
    setNotifications(all?.data);
  }, [all]);

  return (
    <div className="flex justify-end">
      <div className="bg-secondary rounded-[12px] w-[400px]">
        <div className="flex justify-between items-center p-4 pb-0">
          <h3 className="text-[14px] font-cereal_medium text-[#0F172A]">Notifications</h3>
          <button onClick={() => setShowNotification(false)}>
            <IoCloseOutline size={20} />
          </button>
        </div>
        <Divider className="my-3" />
        <div className="px-4">
          <div className="inline-block gap-4">
            {categories.map((item) => (
              <button
                className={`${
                  active === item ? "text-primary bg-[#EFF6FF]" : "text-[#94A3B8] bg-[#F1F5F9]"
                } px-3 me-3 mb-1  h-[32px] rounded-[13px] text-[14px] font-cereal_medium`}
                onClick={() => {
                  item === "All notification" && setNotifications(all.data);
                  item === "Unread" && setNotifications(unread.data);
                  item === "Read" && setNotifications(read.data);
                  setActive(item);
                }}
              >
                {item}
              </button>
            ))}
          </div>
          {isLoading ? (
            <div className="h-[calc(100vh-160px)] mt-5">
              <LinearProgress />
            </div>
          ) : (
            <div className="my-4">
              <h2 className="text-[12px] font-cereal_bold text-[#94A3B8]">Latest</h2>
              <div className="h-[calc(100vh-190px)] overflow-y-scroll">
                {notifications?.map((item, index) => (
                  <div className="flex justify-between items-center py-4" key={index}>
                    <div className="flex items-center gap-3">
                      <span className="w-[35px] h-[35px] rounded-[50%] flex justify-center items-center bg-[#CDDDFF]">
                        <IoCardOutline color="#0166FF" size={20} />
                      </span>
                      <div className="">
                        <h3 className="text-[14px] font-cereal_medium">{item?.header}</h3>
                        <h5 className="text-[12px] text-[#4A545E]">{item.message}</h5>
                      </div>
                    </div>
                    <p className="text-[10px] text-[#94A3B8] font-cereal_medium">{moment(item.date).format("ll")}</p>
                  </div>
                ))}
                <div className="flex h-[calc(100vh-190px)] items-center justify-center">
                  {notifications?.length === 0 && <img src={NoNotification} alt="" />}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
