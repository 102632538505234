import { Outlet } from "react-router-dom";
import InfoGraphics from "../../Assets/images/infographics.svg";

const OnboardingLayout = () => {
  return (
    <div>
      <div className="flex items-start h-[100vh]">
        <div className="bg-primary w-[100%] hidden md:flex items-center justify-center h-[100vh]">
          <div>
            <img src={InfoGraphics} alt="" />
            <h3 className="text-[24px] text-center text-[#fff] font-cereal_bold">ZinID for Vendors Authentication</h3>
            <p className="text-center text-[#fff] font-cereal opacity-[0.7] text-[12px]">
              Get started to enjoy seamless experience with us!
            </p>
          </div>
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default OnboardingLayout;
